export const no = {
  message: {
    layout: {
      login: 'Logg inn',
      logout: 'Logg ut',
      rfidsignin: 'Logg på med RFID',
      SignInpassword: 'Logg på med passord',
      ScanRFID: 'Skann RFID-brikken din',
      ortext: 'ELLER',
      dashboard: 'Dashbord',
      timer: 'Time Management',
      projects: 'Prosjekter',
      stemp: 'Stemple',
      calendar: 'Kalender',
      deviation: 'Avvik',
      home: 'Hjem',
      deliver: 'Levere',
      more: 'Mer',
      files: 'Dokumenter',
      equipments: 'Utstyr',
      checklists: 'Sjekklister',
      drawing: 'Tegninger',
      pressureTestReport: 'Trykktest rapport',
      weldlog: 'Sveiselogg',
      event: 'begivenhet',
      ndt: 'NDT',
      surfaceReport: 'Overflate rapport',
      technicalReport: 'Teknisk rapport',
      matrialList: 'Materialliste',
      pwht: 'PWHT',
      pwps: 'PWPS',
      wps: 'WPS',
      warehouse: 'Lager',
      tasks: 'Oppgaver',
      orders: 'Bestillinger',
      facilities: 'Anlegg',
      chemicallist: 'Stoffkartotek',
      nbselftL: 'NB! Self created',
      asbuilt: 'AS-bygget',
      autoLogout: 'Auto utlogging'
    },
    login: {
      language: 'Språk',
      back: 'Tilbake',
      userName: 'Bruker',
      password: 'Passord',
      authFailed: 'Innlogging feilet !',
      host: 'Domene'
    },
    common: {
      pwaNotEnabled: 'PWA er ikke aktivert, vennligst kontakt administrator',
      delete_row: 'Slett rad',
      install: 'Legg til snarvei på hjemmesiden for hurtig tilgang.',
      justTab: 'Trykk på',
      addToHome: 'deretter <Legg til hjemmeside>',
      english: 'Engelsk',
      norwegian: 'Norsk',
      lithuanian: 'Lithuanian', // need
      polish: 'Polish', // need
      swedish: 'Swedish', // need
      save: 'Lagre',
      saveAndClose: 'Lagre & lukk',
      send: 'Send',
      edit: 'Edit',
      cancel: 'Avbryt',
      update: 'Oppdater',
      delete: 'Slett',
      updateAndClose: 'Oppdater & lukk',
      close: 'Lukk',
      new: 'ny',
      isActive: 'Is active',
      addNew: 'Legg til ny',
      savedSuccess: 'Saved successfully!',
      addedSucess: 'Lagt til!',
      updatedSuccess: 'Oppdatert!',
      deleteSuccess: 'Slettet!',
      backToList: 'Tilbake',
      search: 'Søk',
      yes: 'Ja',
      no: 'Nei',
      isDefault: 'Is Default',
      loadingMsg: 'Loading, Please wait',
      deleteConfirm: 'Vil du slette?',
      areYouSure: 'Er du sikker på at du vil slette?',
      validationIssue: 'Vennligst sjekk de nødvendige feltene!',
      total: 'Total',
      noResults: 'Ingen treff!',
      others: 'Others',
      typeHere: 'Type here...',
      position: 'Position',
      color: 'Color',
      maxCharacters: 'Max {charCount} characters',
      changedPassword: 'Password Changed Successfully',
      import: 'Import',
      note: 'Note',
      invalidEmail: 'Ugyldig epost',
      no_records: 'Ingen opptak funnet!',
      no_data: 'Ingen data tilgjengelig',
      ok: 'Ok',
      date: 'Dato',
      comments: 'Kommentar',
      draftMsg: 'Saved as Draft',
      createProjectRole: 'Create Project Role',
      updateProjectRole: 'Update Project Role',
      module: 'Module',
      selectTemplate: 'Select Template',
      documentTemplate: 'Document Template',
      download: 'Nedlasting',
      uploadedSuccess: 'Uploaded successfully !',
      weekTotalHours: 'Week hrs',
      addWorktype: 'Legg til Arbeidstype',
      updateWorktype: 'Oppdater Arbeidstype',
      worktype: 'Arbeidstype',
      newUpdate: 'Ny oppdatering tilgjengelig!',
      refresh: 'Oppdater', // Forfriske
      moduleName: 'Module Name',
      updatedOn: 'Updated On',
      columns: 'Columns',
      confirmCancel: 'Confirm Cancel',
      areYouSureCancel: 'Are you sure you want to cancel ?',
      not_approved_notification: 'Hei! {count} timeregistrering(er) i uke {weeknumber} er ikke godkjent',
      hourExceedNotification: 'Registrerte timer overstiger 24 timer.',
      options: 'Options',
      label: 'Label',
      value: 'Value',
      confirmStatusChange: 'Confirm Status change',
      areYouSureToChangeStatus: 'Er du sikker på at du vil endre status til {statusText} ?',
      complete: 'fullstendig',
      incomplete: 'ufullstendig',
      name: 'Navn',
      number: 'Nummer',
      noPermission: "Sorry! You don't have permission to {permissionType} {module}",
      charsLimit35: 'Maksimalt {length} tegn',
      confirmAddHour: 'Skiftet er fullført!',
      sureToAddExceedingHour: 'Entered hour is exceeding the Working hours, Are you sure to add ?',
      warning: 'Varsel!',
      required: 'Kreves',
      timerFeatureDisabled: 'Timer feature disabled',
      locationNotSet: 'Location not set',
      type: 'Type',
      changeVideoSource: 'Change video source',
      permissionDenied: 'Kamera tillatelse har blitt nektet! Aktiver det i nettleserinnstillingene!',
      time: 'Time',
      orderno: 'Best.nr',
      ready_for_production: 'Klar for produksjon',
      production: 'Produksjon',
      on_hold: 'On hold',
      finished: 'Ferdig',
      of: 'av',
      ndt_percentage_error: 'NDT-prosent må ha 0 til 100',
      lengthError: 'Lengden må være mindre enn 10',
      update_properties: 'Oppdater egenskaper',
      add_properties: 'Legg til egenskaper',
      preparing_fields: 'Klargjør felt...',
      from_attributs: 'SKJEMAATRIBUTTER',
      enter_title: 'Skriv inn tittelen på hver kolonne:',
      loading_grid: 'Laster rutenett...',
      from_properties: 'FORM EGENSKAPER',
      dragging: 'Dra...',
      catch_up: 'Alle tok igjen!!!',
      add_header: 'Legg til topptekst',
      add_footer: 'Legg til bunntekst',
      drop_fields: 'Slipp feltene her',
      for_header: 'FOR OVERSKRIFT',
      for_footer: 'FOR bunntekst',
      add_html: 'LEGG TIL HTML-INNHOLD',
      relate: 'Forhold',
      unrelate: 'Ikke relatert',
      values: 'Verdier',
      scan: 'Skann',
      filename: 'Filnavn',
      reset: 'Nullstille',
      doYouWantContinue: 'Vil du fortsatt fortsette?',
      stempHint: 'Hvis du velger skiftliste, kan du starte stempelet',
      hoursExeed: 'over 24 timer',
      on: 'På',
      reachedHours: 'Nådde 24 timer',
      invaliHours: 'Ugyldige timer',
      notValidNumber: 'Ikke gyldig nummer',
      invalidName: 'Ugyldig navn',
      filterSaveResult: 'Søk lar deg se filteret du har brukt, og det filtrerte resultatet kan bare sees i rapporten hvis du lagrer sjekklisten',
      seemsInvalid: 'Timer ser ut til å være ugyldige!',
      shiftContinue: 'Stamtimen din fortsetter...',
      no_fields_found: 'Finner ingen sjekklistefelt',
      sharePoint: 'Sharepoint Feil brukernavn og passord eller feil i konfigurasjonen.',
      dragAndDropTips: 'Lagring er obligatorisk ved hvert dra og slipp for å unngå feilplassering av rader',
      afterCopyFilter: 'Filteraksjon vil bli utført til du lagrer sjekklisten',
      archived: 'Arkivert',
      datagrid_filter_help_text_1: 'Resultatene vil bli filtrert på samme måte som forrige gangs filteralternativer.',
      datagrid_filter_help_text_2: 'Når du legger til en ny rad, vil filtre bli fjernet og alle rader vil bli lagt til.',
      datagrid_filter_help_text_3: 'Filtre skiller ikke mellom store og små bokstaver, så vi kan bruke både store og små bokstaver.',
      datagrid_filter_help_text_4: 'Vi kan ikke filtrere samme kolonne to ganger.',
      datagrid_filter_help_text_5: 'Dra og slipp vil bli endret av filterresultatet, hvis brukeren lagrer sjekklisten.',
      datagrid_filter_help_text_6: 'Tekstområdet filtrert av ordet mellom hvilken som helst del av setningen.',
      datagrid_filter_help_text_7: 'Det er obligatorisk å fylle ut både felt og verdien for å utføre filterhandling.',
      sharepointCharacters: "Dette feltet skal ikke inneholde noen av disse tegnene '*:<>?/\\|.'",
      company: 'Selskap',
      filenotfound: 'Filen ble ikke funnet'
    },
    timer: {
      stop: 'Stop',
      ga_til_time: 'Gå til timeliste',
      minutes: 'minutes',
      start: 'Start',
      place: 'Sted',
      hours: 'Timer',
      activity: 'Aktivitet',
      pause: 'Pause',
      from: 'Fra',
      till: 'Til',
      shift: 'Skiftliste',
      startTime: 'Starttid',
      endTime: 'Sluttid',
      editHours: 'Rediger',
      approved: 'Godkjent',
      notApproved: 'Ikke godkjent',
      delivered: 'Levert',
      notDelivered: 'Ikke levert',
      year: 'År',
      week: 'Week',
      nonBillable: 'Ferie/Fravær',
      hourlogging: 'Timelogging',
      noHours: 'Ingenting å levere !',
      deliveredMsg: 'Delivered Successfully',
      addHours: 'Før timer',
      deliverHours: 'Lever timer',
      hoursExceeding: 'Timer Exceeded 8 hours',
      cancelTimer: 'Cancel Timer',
      uploadSuccess: 'Upload Success',
      picture: 'Take a Picture',
      imageNeeded: 'Please upload a picture before you stop the timer!',
      nonbillableworktype: 'Ferie/Fraværstype'
    },
    projects: {
      startDate: 'Startdato',
      endDate: 'Sluttdato',
      all: 'Alle',
      allActive: 'Alle Aktive',
      myAll: 'Alle mine',
      myActive: 'Mine aktive',
      project: 'Prosjekt',
      customer: 'Kunde',
      status: 'Status',
      noActiveProjects: 'No Active Projects!',
      selectProject: 'Velg Prosjekt',
      projectMaterialDisabled: 'Materialer deaktivert for dette prosjektet',
      drawingGroup: 'Tegnegruppe'
    },
    calendar: {
      today: 'Today',
      month: 'Month',
      day: 'Day',
      weekNotExists: 'Uke eksisterer ikke',
      yearValid: 'Må være på fire sifre',
      valid: 'Må være gyldig',
      yearTooLessValidation: 'Må være større enn 1920',
      weekLock: 'Valgte datoer er i den låste uken',
      dayLocked: 'Valgt dag er i den låste uken',
      otherHourEntries: 'Other hour entries'
    },
    shifts: {
      shift: 'Mine vakter',
      documents: 'Dokumenter:',
      not_found: 'Ingen notater eller dokumenter funnet.',
      notValidPausedTime: 'Pausetiden er lengre enn skifttiden',
      continue: 'Fortsette',
      clear: 'Klar'
    },
    deviation: {
      deviations: 'Avvik',
      register_deviation: 'Registrer Avvik',
      view_deviation: 'View Deviation',
      high: 'Høy',
      medium: 'Medium',
      low: 'Lav',
      closed: 'Lukket',
      registered: 'Registrert',
      process: 'Behandling',
      actions: 'Handlinger',
      title: 'Tittel',
      reported_by: 'Rapportert av',
      location: 'Lokasjon',
      reason: 'Årsak',
      document: 'Dokument',
      category: 'Avvikskategori',
      estimate_cost: 'Estimert kost',
      severity: 'Alvorlighetsgrad',
      deviation_status: 'Avvik status',
      deadline: 'Tidsfrist',
      description: 'Beskrivelse',
      responsible: 'Ansvarlig',
      copyto: 'Kopi til',
      upload_files: 'Last opp',
      required_message: 'Feltet er obligatorisk',
      consequences: 'Konsekvenser',
      changeRequest: 'Endringsmelding',
      hse: 'HMS',
      deviation_number: 'Avviksnummer',
      underlying_reason: 'Underliggende grunn',
      corrective_actions: 'Korrigerende tiltak',
      effect_of_measures: 'Effekt av tiltak'
    },
    checklist: {
      title: 'Sjekkliste',
      updateChecklist: 'Oppdater sjekkliste',
      pickCheckList: 'Velg en sjekkliste',
      addCheckList: 'Legg sjekkliste',
      hmsKs: 'HMS/KS',
      templateName: 'Malnavn',
      checklist_recipients: 'E-postmottaker',
      checklist_recipient_hint: 'Skriv inn e-post med komma(,) separert.',
      projectnr: 'PO nr.',
      projectname: 'Prosjektnavn',
      aonr: 'AO nr.',
      workordername: 'AO navn',
      customer: 'Kunde',
      customercontact: 'Kunde kontakt',
      projectleader: 'Prosjektleder',
      importCSV: 'Importer CSV',
      CSVFileInfo: 'Her kan du importere data fra en csv-fil til en sjekkliste.',
      removeRow: 'Fjern 1. rad hvis den har overskrifter.',
      sameOrederColumn: 'Sørg for at kolonnene er i samme rekkefølge som du har definert i sjekklisten.',
      semicolonDelimiter: 'Bruk semikolon som csv-skilletegn.',
      withoutImport: 'Uten import',
      addCSVFile: 'Legg til CSV-fil',
      report_header: 'Report header',
      copy_confirm: 'Do you want to copy the checklist?',
      filter: 'Filter',
      apply: 'Søke om'
    },
    equipments: {
      equipment: 'Utstyr',
      addEquipment: 'Legge til utstyr',
      updateEquipment: 'Utstyr',
      viewEquipment: 'View Equipment',
      active: 'Aktive',
      quarantine: 'Karantene',
      discarded: 'Discarded',
      warrantydate: 'Garantidato',
      dateofPurchase: 'Kjøpsdato',
      vendor: 'Leverandør',
      modelNumber: 'Modellnummer',
      product: 'Produkt',
      serialNumber: 'Serienummer',
      capacity: 'Kapasitet',
      groups: 'Grupper',
      plannedCalibration: 'Planlagt kalibrering',
      scrapDate: 'Kassert dato',
      equipmentNumber: 'Utstyr nummer',
      placement: 'Plassering',
      notifyByEmail: 'Varsling på e-post',
      mustAllocateToProjectOrLocation: 'Må tilordne utstyret til enten prosjekt eller sted.',
      confirm: 'Er du sikker?'
    },
    customModule: {
      modules: 'Moduler',
      customModule: 'Egendefinert modul',
      customModules: 'Egendefinerte moduler',
      uploadDocument: 'Last opp dokument',
      isCompleted: 'Fullført',
      add: 'Legge til',
      urlNotFound: 'URL-en ble ikke funnet',
      completed: 'Fullført',
      notCompleted: 'Ikke fullført',
      fileSizeLimitMsg: 'Filstørrelsesgrensen overskredet for en eller flere filer (maksgrense {maxSize}MB).'
    },
    moduleReports: {
      title: 'Modulrapporter',
      chartyAxis: 'Number Of Records (per module)'
    },
    projectFeature: {
      drawingNumber: 'Tegnings nr.',
      poRanked: 'PO nr.',
      operator: 'Operator',
      reportNumber: 'Report nr.',
      uploadNdtDocument: 'Upload NDT Document',
      aoRanked: 'AO nr.',
      NDTSpecifications: 'NDT spesifikasjon',
      NDTScope: 'NDT Omfang',
      filterMaterials: 'Tilsett materiale',
      chargedLotNumber: 'Charge/Lot nr.',
      selectFiles: 'Velg filer',
      createdOn: 'Opprettet dato',
      weldingID: 'Sveise ID',
      weldNumber: 'Sveise nr.',
      wpsNumber: 'WPS nr.',
      wpsAsComma: 'Skriv inn WPS-nr. Som komma skilt.',
      weldEnd: 'Sveising slutt',
      vtSignDate: 'VT Sign./Dato',
      rtSignDate: 'RT Sign./Dato',
      utSignDate: 'UT Sign./Dato',
      mtPtSignDate: 'MT/PT Sign./Dato',
      createdDate: 'Opprettet Dato',
      tegNr: 'Teg nr.',
      posNr: 'Pos nr.',
      batchNr: 'Batch nr.',
      heatNr: 'Heat nr.',
      onStock: 'På lager',
      out: 'Uttak',
      recordNumber: 'Skiver nr.',
      chartSpeed: 'Kart hastighet',
      chartNumber: 'Kartnr.',
      unrestrictedTo: 'Ubegrenset til',
      restrictedRateOfRise: 'Begrenset økning til',
      holdTemperatureRequired: 'Holde temperatur',
      holdTemperatureActual: 'Faktiske holdetempratur',
      holdTimeActual: 'Faktiske holdetid',
      holdTimeRequired: 'Holdetid',
      restrictedRateDown: 'Begrenset hastighet ned',
      unrestrictedRateOfFall: 'Ubegrenset temperaturfall fra',
      weldDate: 'Sveise dato',
      performedBy: 'Utført av',
      approvedBy: 'Godkjent av',
      approvedDate: 'Godkjent dato',
      weldLogDate: 'Sveisedato',
      productNumber: 'Prod.nr',
      weldLength: 'Sveiselengde (mm)',
      designation_1: 'Del 1 Betegnelse',
      designation_2: 'Del 2 Betegnelse',
      thickness_1: 'Tykkelse 1 (mm)',
      thickness_2: 'Tykkelse 2 (mm)',
      weld_diameter: 'Diameter (mm)',
      notAccepted: 'Ikke akseptert',
      ndtStatus: 'Ndt status',
      isDiameter: 'Rør',
      ndt: '%NDT',
      weldingCompound: 'Fugetype',
      batch_lot: 'Batc/lot',
      choose: 'velg',
      pipe_description: 'Rørbeskrivelse'
    },
    whsDashboard: {
      scanText: 'Skann ordrenummer eller strekkode',
      customerOrder: 'Kundeordre',
      receiveSupplierOrder: 'Motta leverandørordre',
      adjustmentOrders: 'Justeringsordre',
      transferOrders: 'Overføringsordre',
      cycleCount: 'Varetelling',
      product: 'Produkter',
      supplierOrder: 'Innkjøpsordre',
      returnOrder: 'Returordre'
    },
    ccsheet: {
      warehouse: 'Lager',
      created: 'Opprettet',
      counted: 'Telt',
      count: 'Telle',
      reCount: 'Telle om',
      closeComplete: 'Lukk og fullfør',
      unit: 'Enh',
      stock: 'Lager',
      scanLocation: 'Skann eller skriv inn lokasjon',
      nextLoc: 'Neste lok',
      info: 'Melding!',
      continueCount: 'Fortsett å telle',
      clearAndRecount: 'Nullstill og telle om',
      completeRecount: 'Fullfør omtelling',
      ccsheetComplete: 'Telleskjema vil bli fullført. Vil du fortsette?',
      noSheetFound: 'Ingen telleskjema funnet!',
      noProductOnLocation: 'Ingen produkter funnet på denne lokasjonen!',
      stockTake: 'Telling har blitt fullført for denne lokasjonen. Ønsker du å forsette med denne lokasjonen eller nullstille data og telle om igjen?',
      ccsheetAddProduct: 'Produkt ligger ikke i telleskjema. Vil du legge til?'
    },
    product: {
      supplierartno: 'Leverandørens artikkelnummer',
      supplier: 'Leverandørnavn',
      barcode: 'Strekkode',
      noProductFound: 'Ingen produkter funnet!',
      minQty: 'Min.ant.',
      orderQty: 'Best.ant.',
      qty: 'Mottat qty',
      batch: 'Batch Nr',
      scanLoc: 'Velg Lok',
      noLocFound: 'Ingen lokasjon funnet!'
    },
    order: {
      products: 'Produkter',
      avail: 'Benytte',
      ordered: 'Rekke.',
      received: 'Motta.',
      pickedQty: 'Pic.',
      pickOrder: 'Velg ordre',
      delivery: 'levere',
      rest: 'Rest',
      complete: 'Ferdig',
      pick: 'Hente',
      pickProducts: 'Velg produkter',
      scanProduct: 'Skann strekkode for et produkt',
      scanBatch: 'Skann batchnummer',
      picked: 'Hentet',
      toPick: 'Skal hentes',
      location: 'Lok',
      pickLocation: 'Velg lokasjon',
      qtyExceeds: 'Antall overskrider',
      noOrderFound: 'Ingen ordre funnet!',
      notValidBatch: 'Batchnummer er ugyldig!',
      receiveOrder: 'Motta ordre',
      receive: 'Mottat',
      batchExists: 'Batchnummer eksisterer allerede!',
      receiveWarning: 'Vil du legge til {qty} stk {product} på lager?',
      completedSuccess: 'Completed successfully!',
      addFreight: 'Vil du legge til frakt ?',
      cost: 'Kostnad',
      price: 'Pris',
      orderedQtyHigh: 'Antall mottatt er høyere enn bestilt, vil du fortsette?  ( Ja / Nei )',
      collect: 'samle inn',
      receiving: 'Receiving',
      availableToPick: 'Avail qty(s) to receive',
      adjust: 'Adjust',
      orderComplete: 'Order will be completed. Continue?'
    },
    tasks: {
      forYour: 'For din',
      information: 'Informasjon',
      acknowledge: 'Bekreft',
      review: 'Revider',
      approval: 'Godkjenning',
      subject: 'Emne',
      dueDate: 'Due date',
      approve: 'Godkjenn',
      disapprove: 'Ikke godkjenn',
      keep: 'Beholde',
      remove: 'Fjerne'
    },
    mixed: {
      scanSerialNumber: 'Skann serienummer',
      noEquipmentFound: 'Ingen utstyr funnet',
      sharedUrl: 'Delt URL',
      checklistfiles: 'Sjekklistefiler',
      attachments: 'Vedlegg',
      signature: 'Signatur',
      nochecklists: 'Ingen sjekklister',
      checklistName: 'Sjekkliste Navn',
      noOfDocuments: 'Nei av dokumenter',
      documentTitle: 'Dokument tittel',
      qrCode: 'QR Kode',
      qrQty: 'QR Quantity',
      irscanner: 'Ir-skanner',
      scanQRCode: 'Skann QR-kode',
      attachmentsBelow: 'Finn vedlegg nedenfor',
      noChemicalFound: 'Ingen kjemikalieliste funnet'
    },
    chemicallist: {
      chemical: 'Kjemisk',
      provider: 'Forsørger',
      repository: 'Oppbevaringssted',
      last_revised: 'Sist revidert',
      viewChemical: 'Kjemisk',
      chemicalName: 'Kjemisk navn'
    },
    news: {
      news: 'Nyheter',
      next: 'Neste',
      previous: 'Forrige'
    }
  }
}
