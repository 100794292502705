export const lt = {
  message: {
    layout: {
      login: 'Prisijungimas',
      ortext: 'ARBA',
      logout: 'Atsijungimas',
      rfidsignin: 'Prisijunkite naudodami RFID',
      SignInpassword: 'Prisijunkite kaip slaptažodį',
      ScanRFID: 'Nuskaitykite savo RFID žymą',
      dashboard: 'Prietaisų skydelis',
      timer: 'Laikmatis',
      projects: 'Projektai',
      stemp: 'Pažymėkite į',
      calendar: 'Kalendorius',
      deviation: 'Nukrypimas',
      home: 'Pradžia',
      deliver: 'Pristatymas',
      more: 'Daugiau',
      files: 'Dokumentai',
      equipments: 'Įranga',
      checklists: 'Patikrinimo sąrašai',
      drawing: 'Piešimas',
      pressureTestReport: 'Slėgio bandymo ataskaita',
      weldlog: 'Suvirinimo žurnalas',
      event: 'įvykis',
      ndt: 'NDT',
      surfaceReport: 'Paviršiaus ataskaita',
      technicalReport: 'Techninė ataskaita',
      matrialList: 'Medžiagų sąrašas',
      pwht: 'PWHT',
      pwps: 'PWPS',
      wps: 'WPS',
      warehouse: 'Sandėlis',
      tasks: 'Užduotys',
      orders: 'Užsakymai',
      facilities: 'Priemonė',
      chemicallist: 'Medžiagų sąrašas',
      asbuilt: 'Kaip pastatyta',
      autoLogout: 'automatinis atsijungimas'
    },
    login: {
      language: 'Kalba',
      back: 'Atgal',
      userName: 'Vartotojo vardas',
      password: 'Slaptažodis',
      authFailed: 'Autentiškumo nustatymas nepavyko !',
      host: 'Šeimininkas'
    },
    common: {
      pwaNotEnabled: 'PWA neįjungtas, susisiekite su administratoriumi',
      delete_row: 'Ištrinti eilutę',
      install: 'Įdiekite šią programą savo pagrindiniame ekrane, kad galėtumėte greitai ir lengvai pasiekti, kai esate kelyje.',
      justTab: 'Tiesiog bakstelėkite',
      addToHome: 'tada "Įtraukti į pagrindinį ekraną".',
      english: 'Lietuvių kalba',
      norwegian: 'Norvegų kalba',
      lithuanian: 'Lithuanian', // need
      polish: 'Polish', // need
      swedish: 'Swedish', // need
      save: 'Išsaugoti',
      saveAndClose: 'Išsaugoti ir uždaryti',
      send: 'Siųsti',
      edit: 'Redaguoti',
      cancel: 'Atšaukti',
      update: 'Atnaujinti',
      delete: 'Ištrinti',
      updateAndClose: 'Atnaujinti ir uždaryti',
      close: 'Uždaryti',
      new: 'Naujas',
      isActive: 'Yra aktyvus',
      addNew: 'Pridėti naują',
      savedSuccess: 'Sėkmingai išsaugota!',
      addedSucess: 'Sėkmingai pridėta !',
      updatedSuccess: 'Sėkmingai atnaujinta !',
      deleteSuccess: 'Sėkmingai pašalinta !',
      backToList: 'Grįžti į sąrašą',
      search: 'Paieška',
      yes: 'Taip',
      no: 'Ne',
      isDefault: 'Ar numatytoji',
      loadingMsg: 'Kraunasi, palaukite',
      deleteConfirm: 'Ištrinti Patvirtinti',
      areYouSure: 'Ar tikrai norite ištrinti ?',
      validationIssue: 'Patikrinkite privalomus laukus!',
      total: 'Iš viso',
      noResults: 'Rezultatų nerasta !',
      others: 'Kiti',
      typeHere: 'Įveskite čia...',
      position: 'Pozicija',
      color: 'Spalva',
      maxCharacters: 'Maksimalus {charCount} simbolių skaičius',
      changedPassword: 'Slaptažodis sėkmingai pakeistas',
      import: 'Importuoti',
      note: 'Pastaba',
      invalidEmail: 'Neteisingas el. paštas',
      no_records: 'Įrašų nerasta!',
      no_data: 'Nėra duomenų',
      ok: 'Gerai',
      date: 'Data',
      comments: 'Komentarai',
      draftMsg: 'Išsaugota kaip projektas',
      createProjectRole: 'Sukurti projekto vaidmenį',
      updateProjectRole: 'Atnaujinti projekto vaidmenį',
      module: 'Modulis',
      selectTemplate: 'Pasirinkite šabloną',
      documentTemplate: 'Dokumento šablonas',
      download: 'Atsisiųsti',
      uploadedSuccess: 'Sėkmingai įkelta !',
      weekTotalHours: 'Savaitės val.',
      addWorktype: 'Pridėti darbo tipą',
      updateWorktype: 'Atnaujinti darbo tipą',
      worktype: 'Darbo tipas',
      newUpdate: 'Galimas naujas atnaujinimas!',
      refresh: 'Atnaujinti',
      moduleName: 'Modulio pavadinimas',
      updatedOn: 'Atnaujinta',
      columns: 'Stulpeliai',
      confirmCancel: 'Patvirtinti Atšaukti',
      areYouSureCancel: 'Ar tikrai norite atšaukti ?',
      not_approved_notification: 'Sveiki! {count} laiko registracija (-os) per savaitę {weeknumber} nepatvirtinta',
      hourExceedNotification: 'Lora inserita supera le 24 ore',
      options: 'Parinktys',
      label: 'Etiketė',
      value: 'Reikšmė',
      confirmStatusChange: 'Patvirtinti būsenos pasikeitimą',
      areYouSureToChangeStatus: 'Ar tikrai norite pakeisti būseną į {statusText} ?',
      complete: 'completare',
      incomplete: 'incompleto',
      name: 'Pavadinimas',
      number: 'Skaičius',
      noPermission: 'Atsiprašome! Jūs neturite leidimo {permissionType} {modulis}',
      charsLimit35: 'Prašome įvesti ne daugiau kaip {ilgis} simbolių',
      confirmAddHour: 'Patvirtinkite, kad pridėtumėte viršijančias valandas',
      sureToAddExceedingHour: 'Įvesta valanda viršija darbo valandas, Ar tikrai norite pridėti ?',
      warning: 'Įspėjimas',
      required: 'Reikalinga',
      timerFeatureDisabled: 'Laikmačio funkcija išjungta',
      locationNotSet: 'Nenustatyta vieta',
      type: 'Tipas',
      changeVideoSource: 'Keisti vaizdo šaltinį',
      permissionDenied: 'Fotoaparato leidimas yra uždraustas!, įgalinkite jį naršyklės nustatymuose!',
      time: 'Time',
      orderno: 'Order No',
      ready_for_production: 'Klar for produksjon',
      production: 'Produksjon',
      on_hold: 'On hold',
      finished: 'Ferdig',
      of: 'di',
      ndt_percentage_error: 'NDT procentas turi būti nuo 0 iki 100',
      lengthError: 'Ilgis turi būti mažesnis nei 10',
      update_properties: 'Atnaujinti ypatybes',
      add_properties: 'Pridėti ypatybes',
      preparing_fields: 'Ruošiami laukai...',
      from_attributs: 'FORMOS ATtributai',
      enter_title: 'Įveskite kiekvieno stulpelio pavadinimą:',
      loading_grid: 'Įkeliami tinkleliai...',
      from_properties: 'MODULIO SAVYBĖS',
      dragging: 'Vilkite...',
      catch_up: 'Viskas paimta!!!',
      add_header: 'Pridėti antraštę',
      add_footer: 'Pridėti poraštę',
      drop_fields: 'Palikite laukus čia',
      for_header: 'PAGAL ANTRAŠTĘ',
      for_footer: 'PUSLAPIO PĖDAI',
      add_html: 'PRIDĖTI HTML TURINĮ',
      relate: 'Susiję',
      unrelate: 'Nesusijusios',
      values: 'Vertybės',
      scan: 'Nuskaityti',
      filename: 'Failo pavadinimas',
      reset: 'Atkurti',
      doYouWantContinue: 'Ar vis dar norite tęsti?',
      stempHint: 'Jei pasirinksite Shift, galėsite pradėti štampuoti',
      hoursExeed: 'viršija 24 valandas',
      on: 'ĮJUNGTA',
      reachedHours: 'Pasiekė 24 val',
      invaliHours: 'Netinkamas laikas',
      notValidNumber: 'Neteisingas numeris',
      invalidName: 'Neteisingas pavadinimas',
      filterSaveResult: 'Paieška leidžia matyti pritaikytą filtrą, o išfiltruotą rezultatą galima matyti ataskaitoje tik išsaugojus kontrolinį sąrašą',
      seemsInvalid: 'Atrodo, kad laikas netinkamas!',
      shiftContinue: 'Jūsų plakimo valanda tęsiasi...',
      no_fields_found: 'Kontrolinio sąrašo laukų nerasta',
      sharePoint: '„Sharepoint“ gali turėti neteisingus kredencialus arba trūkti konfigūracijų!.',
      specifyTheNumberOfColumnsToBeIncludedInTheGridComponent: 'Nurodykite stulpelių, kurie turi būti įtraukti į tinklelio komponentą, skaičių',
      dragAndDropTips: 'Išsaugoti privaloma kiekvieną kartą nuvilkant ir numetant, kad būtų išvengta klaidingų eilučių',
      afterCopyFilter: 'Filtro veiksmas bus atliekamas tol, kol išsaugosite kontrolinį sąrašą',
      archived: 'Archyvuota',
      datagrid_filter_help_text_1: 'Rezultatai bus filtruojami taip pat, kaip ir paskutinį kartą filtravimo parinktys.',
      datagrid_filter_help_text_2: 'Pridėjus naują eilutę, filtrai bus pašalinti ir visos eilutės bus pridėtos.',
      datagrid_filter_help_text_3: 'Filtrai neskiria didžiųjų ir mažųjų raidžių, todėl galime naudoti ir didžiąsias, ir mažąsias.',
      datagrid_filter_help_text_4: 'Negalime du kartus filtruoti to paties stulpelio.',
      datagrid_filter_help_text_5: 'Jei vartotojas išsaugos kontrolinį sąrašą, vilkimas bus pakeistas pagal filtro rezultatą.',
      datagrid_filter_help_text_6: 'Teksto sritis, filtruojama pagal žodį tarp bet kurios sakinio dalies.',
      datagrid_filter_help_text_7: 'Privaloma užpildyti abu laukus, o reikšmė – atlikti filtro veiksmą.',
      sharepointCharacters: 'Šiame lauke neturėtų būti šių simbolių „*:<>?/\\|.“',
      company: 'Bendrovė',
      filenotfound: 'Failas nerastas'
    },
    timer: {
      stop: 'Sustabdyti',
      ga_til_time: 'Eiti į valandų sąrašą',
      minutes: 'minučių',
      start: 'Pradėti',
      place: 'Vieta',
      hours: 'Valandos',
      activity: 'Veikla',
      pause: 'Pauzė',
      from: 'Iš',
      till: 'Iki',
      shift: 'Mine vakter',
      startTime: 'Pradžios laikas',
      endTime: 'Pabaigos laikas',
      editHours: 'Redaguoti valandą',
      approved: 'Patvirtinta',
      notApproved: 'Nepatvirtinta',
      delivered: 'Pristatytas',
      notDelivered: 'Nepristatytas',
      year: 'Metai',
      week: 'Savaitė',
      nonBillable: 'Atostogos / nebuvimas',
      hourlogging: 'Valandų registravimas',
      noHours: 'Nerasta valandų pristatymui !',
      deliveredMsg: 'Sėkmingai pristatyta',
      addHours: 'Pridėti valandą',
      deliverHours: 'Pristatymo valandos',
      hoursExceeding: 'Laikmatis viršijo 8 val.',
      cancelTimer: 'Atšaukti laikmatį',
      uploadSuccess: 'Sėkmingas įkėlimas',
      picture: 'Nufotografuoti',
      imageNeeded: 'Prieš sustabdydami laikmatį įkelkite nuotrauką!',
      nonbillableworktype: 'Atostogų / nebuvimo tipas'
    },
    projects: {
      startDate: 'Pradžios data',
      endDate: 'Pabaigos data',
      all: 'Visi',
      allActive: 'Visi Aktyvūs',
      myAll: 'Mano visi',
      myActive: 'Mano Aktyvus',
      project: 'Projektas',
      customer: 'Klientas',
      status: 'Būklė',
      noActiveProjects: 'Nėra aktyvių projektų!',
      selectProject: 'Pasirinkite projektą',
      projectMaterialDisabled: 'Medžiagos, kurios šiam projektui yra išjungtos',
      drawingGroup: 'Piešimo grupė'
    },
    calendar: {
      today: 'Šiandien',
      month: 'Mėnuo',
      day: 'Diena',
      weekNotExists: 'Savaitė neegzistuoja',
      yearValid: 'Turi būti keturi skaitmenys',
      valid: 'Turi būti galiojantis',
      yearTooLessValidation: 'Metai turi būti didesni nei 1920 m.',
      weekLock: 'Pasirinktos datos yra užrakintoje savaitėje',
      dayLocked: 'Pasirinkta data yra užrakintoje savaitėje',
      otherHourEntries: 'Kiti valandų įrašai'
    },
    shifts: {
      shift: 'Mine vakter', // need
      documents: 'Dokumentai:',
      not_found: 'Aprašymo ir url nerastas.',
      notValidPausedTime: 'Pauzės laikas yra ilgesnis nei pamainos laikas',
      continue: 'Tęsk',
      clear: 'Aišku'
    },
    deviation: {
      deviations: 'Nuokrypis',
      register_deviation: 'Registruoti nukrypimą',
      view_deviation: 'View nukrypimą',
      high: 'Didelis',
      medium: 'Vidutinis',
      low: 'Mažas',
      closed: 'Uždaryta',
      registered: 'Registruota',
      process: 'Procesas',
      actions: 'Veiksmai',
      title: 'Medžiagos',
      reported_by: 'Pranešė',
      location: 'Loc',
      reason: 'Priežastis',
      document: 'Dokumentas',
      category: 'Kategorija',
      estimate_cost: 'Sąmata Išlaidos',
      severity: 'Sunkumas',
      deviation_status: 'Nukrypimas Būklė',
      deadline: 'Terminas',
      description: 'Aprašymas',
      responsible: 'Atsakingas',
      copyto: 'Kopija Į',
      upload_files: 'Įkelti',
      required_message: 'Šis laukas yra privalomas',
      consequences: 'Pasekmės',
      changeRequest: 'Modifikuoti prašymą',
      hse: 'HSE',
      deviation_number: 'Deviation Number',
      underlying_reason: 'Underlying Reason',
      corrective_actions: 'Corrective Actions',
      effect_of_measures: 'Effect Of Measures'
    },
    checklist: {
      title: 'Lista di controllo', // need
      updateChecklist: 'Atnaujinti kontrolinį sąrašą',
      pickCheckList: 'Pasirinkite kontrolinį sąrašą',
      addCheckList: 'Pridėti kontrolinį sąrašą',
      hmsKs: 'HMS/KS',
      templateName: 'Nome modello',
      checklist_recipients: 'Destinatario delle-mail',
      checklist_recipient_hint: 'Immettere le-mail utilizzando la virgola (,) separata.',
      projectnr: 'PO Ranked',
      projectname: 'Nome del progetto',
      aonr: 'AO Ranked',
      workordername: "Nome dell'ordine di lavoro",
      customer: 'Cliente',
      customercontact: 'Contatto con il cliente',
      projectleader: 'Capo progetto',
      report_header: 'Report header',
      importCSV: 'Import CSV',
      CSVFileInfo: 'Here You can import data from an csv file into a checklist.',
      removeRow: 'Remove 1. row if it has headers.',
      sameOrederColumn: 'Make sure the columns are in the same order as u have defined it in the checklist.',
      semicolonDelimiter: 'Please use semicolon as csv delimiter.',
      withoutImport: 'Without Import',
      addCSVFile: 'Add CSV File',
      copy_confirm: 'Do you want to copy the checklist?',
      filter: 'Filtras',
      apply: 'Taikyti'
    },
    equipments: {
      equipment: 'Įranga',
      addEquipment: 'Pridėti įrangą',
      updateEquipment: 'Atnaujinti įrangą',
      viewEquipment: 'View Equipment',
      active: 'Aktyvus',
      quarantine: 'Karantinas',
      discarded: 'Išmestas',
      warrantydate: 'Garantijos data',
      dateofPurchase: 'Pirkimo data',
      vendor: 'Pardavėjas',
      modelNumber: 'Modelio numeris',
      product: 'Produktas',
      serialNumber: 'Serijinis numeris',
      capacity: 'Talpa',
      groups: 'Grupės',
      plannedCalibration: 'Planuojamas kalibravimas',
      scrapDate: 'Lūžio data',
      equipmentNumber: 'Įrangos numeris',
      placement: 'Vieta',
      notifyByEmail: 'Pranešti el. paštu',
      mustAllocateToProjectOrLocation: 'Turi priskirti įrangą projektui arba vietai.',
      confirm: 'Ar tu tuo tikras?'
    },
    customModule: {
      modules: 'Moduliai',
      customModule: 'Pasirinktinis modulis',
      customModules: 'Pasirinktiniai moduliai',
      uploadDocument: 'Įkelti dokumentą',
      isCompleted: 'Yra užbaigtas',
      add: 'Pridėti',
      urlNotFound: 'Atsisiųsti url nerastas',
      completed: 'Užbaigta',
      notCompleted: 'Neužbaigta',
      fileSizeLimitMsg: 'Viršyta vieno ar daugiau failų dydžio riba (Max.Limit {maxSize}MB).'
    },
    moduleReports: {
      title: 'Module reports', // need
      chartyAxis: 'Įrašų skaičius (vienam moduliui)'
    },
    projectFeature: {
      drawingNumber: 'Brėžinio Nr.',
      poRanked: 'PO Užimama vieta',
      operator: 'Operatorius',
      reportNumber: 'Ataskaitos Nr.',
      uploadNdtDocument: 'Įkelti NDT dokumentą',
      aoRanked: 'AO reitingas',
      NDTSpecifications: 'NDT specifikacijos',
      NDTScope: 'NDT taikymo sritis',
      filterMaterials: 'Filtravimo medžiagos',
      chargedLotNumber: 'Charde/Lot nr.',
      selectFiles: 'Pasirinkti failus',
      createdOn: 'Sukurta nuo',
      weldingID: 'Suvirinimo ID',
      weldNumber: 'Suvirinimo Nr.',
      wpsNumber: 'WPS nr.',
      wpsAsComma: 'Įveskite WPS Nr. Kaip kableliais atskirtą.',
      weldEnd: 'Suvirinimo pabaiga',
      vtSignDate: 'VT ženklas./ data',
      rtSignDate: 'RT Sign./Data',
      utSignDate: 'UT Sign./Data',
      mtPtSignDate: 'MT/PT Sign./Data',
      createdDate: 'Sukūrimo data',
      tegNr: 'Teg nr.',
      posNr: 'Pozicijos Nr.',
      batchNr: 'Partijos Nr.',
      heatNr: 'Šilumos Nr.',
      onStock: 'Sandėlyje',
      out: 'Išparduota',
      recordNumber: 'Įrašo numeris',
      chartSpeed: 'Diagrama Greitis',
      chartNumber: 'Diagramos numeris',
      unrestrictedTo: 'Neribota iki',
      restrictedRateOfRise: 'Apribotas Kilimo greitis',
      holdTemperatureRequired: 'Reikiama laikymo temperatūra',
      holdTemperatureActual: 'Faktinė sulaikymo temperatūra',
      holdTimeActual: 'Laikymo laikas Faktinis',
      holdTimeRequired: 'Reikalaujama išlaikymo trukmė',
      restrictedRateDown: 'Apribotas mažėjimo greitis',
      unrestrictedRateOfFall: 'Neribotas kritimo greitis',
      weldDate: 'Suvirinimo data',
      performedBy: 'Atliko',
      approvedBy: 'Patvirtino',
      approvedDate: 'Patvirtinta data',
      weldLogDate: 'Suvirinimo data',
      productNumber: 'Numero del prodotto',
      weldLength: 'Lunghezza della saldatura (millimetro)',
      designation_1: 'Parte 1 Designazione',
      designation_2: 'Parte 2 Designazione',
      thickness_1: 'Spessore 1 (mm)',
      thickness_2: 'Spessore 2 (mm)',
      weld_diameter: 'Diametro della saldatura (millimetro)',
      notAccepted: 'Non accettato',
      ndtStatus: 'Stato Ndt',
      isDiameter: 'È il diametro',
      ndt: '%NDT',
      weldingCompound: 'Composto per saldatura',
      batch_lot: 'Batc/lot',
      choose: 'Pasirinkite',
      pipe_description: 'Vamzdžio aprašymas'
    },
    whsDashboard: {
      scanText: 'Nuskaityti užsakymo numerį arba brūkšninį kodą',
      customerOrder: 'Kliento užsakymai',
      receiveSupplierOrder: 'Gauti tiekėjo užsakymus',
      adjustmentOrders: 'Koregavimo užsakymai',
      transferOrders: 'Perdavimo užsakymai',
      cycleCount: 'Ciklo skaičiavimas',
      product: 'Product',
      supplierOrder: 'Tiekėjo užsakymas',
      returnOrder: 'Grąžinimo užsakymas'
    },
    ccsheet: {
      warehouse: 'Warehouse', // need
      created: 'Sukurta',
      counted: 'Suskaičiuota',
      count: 'Skaičius',
      reCount: 'Perskaičiavimas',
      closeComplete: 'Uždaryti ir užbaigti',
      unit: 'Vienetas',
      stock: 'Atsargos',
      scanLocation: 'Nuskaitykite arba įveskite vietą',
      nextLoc: 'Kita vieta',
      info: 'Žinutė!',
      continueCount: 'Tęsti skaičiavimą',
      clearAndRecount: 'Išvalyti ir perskaičiuoti',
      completeRecount: 'Užbaigti perskaičiavimą',
      ccsheetComplete: 'CCSheet bus užpildytas. Tęsti?',
      noSheetFound: 'CCSheet nerastas!',
      noProductOnLocation: 'Šioje vietoje nerastas joks produktas!',
      stockTake: 'Pasirinktos vietos inventorizacija jau atlikta. Ar norite tęsti skaičiavimą šioje vietoje, ar išvalyti duomenis ir atlikti naują inventorizaciją?',
      ccsheetAddProduct: 'Produktas nepridėtas į šią ccsheet norėtumėte pridėti ?'
    },
    product: {
      supplierartno: 'Supplier article number',
      supplier: 'Supplier Name',
      barcode: 'Barcode',
      noProductFound: 'Produkto nerasta!',
      minQty: 'Mažiausias kiekis',
      orderQty: 'Užsakymo kiekis',
      qty: 'Kiekis',
      batch: 'Partija',
      scanLoc: 'Select Loc.',
      noLocFound: 'Vietos nerasta!'
    },
    order: {
      products: 'Produktai',
      avail: 'Turima',
      ordered: 'Ord.',
      received: 'Rec.',
      pickedQty: 'Nuotr.',
      pickOrder: 'Parinkti užsakymą',
      delivery: 'Del',
      pickProducts: 'Velg produkter',
      rest: 'Poilsis',
      complete: 'Pilnas',
      pick: 'Išsirinkti',
      scanProduct: 'Nuskaitykite gaminio brūkšninį kodą',
      scanBatch: 'Nuskaitykite partijos numerį',
      picked: 'Surinkta',
      toPick: 'Parinkti',
      location: 'Loc', // need
      pickLocation: 'Pasirinkite vietą',
      qtyExceeds: 'Kiekis viršija',
      noOrderFound: 'Užsakymo nerasta!',
      notValidBatch: 'Partijos numeris negalioja!',
      receiveOrder: 'Gauti užsakymą',
      receive: 'Gauti',
      batchExists: 'Partijos numeris jau egzistuoja!',
      receiveWarning: 'Ar norėtumėte papildyti {produkto} atsargas {kiekis} vnt.',
      completedSuccess: 'Sėkmingai įvykdyta!',
      addFreight: 'Ar norite pridėti krovinį?',
      cost: 'Išlaidos',
      price: 'Kaina',
      orderedQtyHigh: 'Gautas kiekis yra didesnis nei užsakytas, ar norite tęsti?',
      collect: 'Surinkti',
      receiving: 'Gavimas',
      availableToPick: 'Gautinas (-i) kiekis (-ai), kurį (-iuos) reikia gauti',
      adjust: 'Koreguoti',
      orderComplete: 'Užsakymas bus įvykdytas. Tęsti?'
    },
    tasks: {
      forYour: 'Jūsų',
      information: 'informacijai',
      acknowledge: 'Patvirtinkite',
      review: 'Peržiūrėkite',
      approval: 'Patvirtinimas',
      subject: 'Tema',
      dueDate: 'Terminas',
      approve: 'Patvirtinti',
      disapprove: 'Nepritarti',
      keep: 'Palikti',
      remove: 'Pašalinti'
    },
    mixed: {
      scanSerialNumber: 'Scan serial number',
      noEquipmentFound: 'No equipment found',
      sharedUrl: 'Bendrinamas URL',
      checklistfiles: 'Kontrolinių bylų failai',
      attachments: 'Priedai',
      signature: 'Parašas',
      nochecklists: 'Nėra kontrolinių sąrašų',
      checklistName: 'Kontrolinio sąrašo pavadinimas',
      noOfDocuments: 'Dokumentų Nr',
      documentTitle: 'Dokumento pavadinimas',
      qrCode: 'QR Code',
      qrQty: 'QR Quantity',
      scanQRCode: 'Skanuokite QR kodą',
      irscanner: 'irskeneris',
      attachmentsBelow: 'Raskite toliau pateiktus priedus',
      noChemicalFound: 'Cheminių medžiagų sąrašo nerasta'
    },
    chemicallist: {
      chemical: 'Cheminis',
      provider: 'Teikėjas',
      repository: 'Saugykla',
      last_revised: 'Paskutinį kartą peržiūrėta',
      viewChemical: 'Cheminis',
      chemicalName: 'Cheminis pavadinimas'
    },
    news: {
      news: 'Naujienos',
      next: 'Kitas',
      previous: 'Ankstesnis'
    }
  }
}
